<template>
  <div>
    <span
      v-if="
        (typeof vendorGlobalInsuranceID === 'string' ||
          typeof vendorGlobalInsuranceID === 'number') &&
          documentID > 0
      "
    >
      <button
        class="button is-info is-light is-small is-outline is-rounded"
        @click.prevent="downloadPdf(vendorGlobalInsuranceID)"
      >
        Pdf
      </button>
    </span>
    <span v-else>
      &nbsp;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    vendorGlobalInsuranceID: [Number, String],
    documentID: [Number, String],
    downloadPdf: Function
  }
}
</script>
