<template>
  <div coverages>
    <div
      coverages
      v-for="(coverage, indexA) in list"
      :key="indexA"
      :style="{
        justifyContent: 'space-between',
        ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
        padding: '0px 10px'
      }"
    >
      <div class="pb-5" :style="{ flexBasis: '33%' }">
        <div
          class="columns pt-5"
          v-if="indexA == 0"
          :style="{ color: color[8], fontWeight: 'bold' }"
        >
          <div class="column is-6">Coverage Type</div>
          <div class="column is-2 has-text-right">Amount</div>
        </div>
        <div
          class="columns pb-5"
          :style="{ color: color[8], height: '27px' }"
          v-if="
            coverage.coverageType &&
              coverage.coverageType != undefined &&
              coverage.coverageAmount &&
              coverage.coverageAmount != undefined
          "
        >
          <div class="column is-6">{{ coverage.coverageType }}</div>
          <div class="column is-2 has-text-right">{{ coverage.coverageAmount | currency }}</div>
        </div>
        <div v-else :style="{ color: color[8] }">
          N/A
        </div>
      </div>
    </div>

    <div
      coverages-not-found
      v-if="!Array.isArray(list) || list.length === 0"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        padding: '8px 12px',
        borderRadius: '6px',
        color: color[8]
      }"
    >
      Coverage type details were not found.
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'

/*
  <Coverages :coverages="coverages" />
*/
export default {
  props: {
    coverages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color,
      list: this.coverages
    }
  },
  mounted() {},
  methods: {},
  watch: {}
}
</script>
