import _get from 'lodash/get'
import { vendorList } from '@/services/Vendors/store'
import { vendorInsuranceCoverage } from '@/services/Vendors/VendorInsuranceCoverages/store'
import kms from '@/services/kms'
import Download from '../components/Download'
import { base64toBlob, showPdf } from '@/services/blobutility'
import { notifyProblem, notifyMessage, notifyWarning } from '@/services/notify'
import Details from './../components/Details'

export const methods = {
  rowOnClick() {},

  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const policyNumber = _get(entry, 'policyNumber', '')
      const isVerified = _get(entry, 'isVerified', false)
      const insuranceCompany = _get(entry, 'insuranceCompany', '')
      const agentName = _get(entry, 'insuranceAgentName', '')
      const agentPhone = _get(entry, 'insuranceAgentPhone', '')
      const agentEmail = _get(entry, 'insuranceAgentEmail', '')

      const coverages = _get(entry, 'vendorGlobalInsuranceCoverages', null)

      return {
        _expandable: {
          // expanded: false,

          // whether or not to show the expandable icon
          show: true,

          // the component to show when the expandable icon is pressed
          component: Details,

          // props to send to the expandable icon
          props: {
            coverages: coverages
          }
        },
        policyNumber: policyNumber,
        isVerified: isVerified,
        insuranceCompany: insuranceCompany,
        agentName: agentName,
        agentPhone: agentPhone,
        agentEmail: agentEmail,
        certificate: {
          component: Download,
          props: {
            documentID: _get(entry, ['certificateDocumentID'], 0),
            vendorGlobalInsuranceID: _get(entry, 'vendorGlobalInsuranceID', 0),
            downloadPdf: vendorGlobalInsuranceID => {
              this.downloadPdf(vendorGlobalInsuranceID)
            }
          }
        }
      }
    })

    this.rows = rows
  },

  async loadVendorInsuranceCoverages() {
    if (this.isDebug == true) console.debug('in loadVendorInsuranceCoverages()...')
    await this.getVendorGlobalID()

    await vendorInsuranceCoverage
      .dispatch('getVendorInsuranceCoverageList', {
        vendorGlobalID: this.vendorGlobalID
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    if (this.isDebug == true) console.debug('params vendorID=' + this.vendorID)

    this.rows = []

    this.loadVendorInsuranceCoverages()

    this.loading = false
  },

  async getVendorGlobalID() {
    await vendorList
      .dispatch('getVendorById', {
        vendorID: this.vendorID
      })
      .then(({ result }) => {
        if (result) {
          this.vendorGlobalID = result.vendorGlobalID
          if (this.isDebug == true) console.debug('getVendorGlobalID=' + this.vendorGlobalID)
        }
      })
  },

  async downloadPdf(vendorGlobalInsuranceID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!vendorGlobalInsuranceID || vendorGlobalInsuranceID == undefined) {
      notifyProblem('The vendorGlobalInsuranceID is invalid for the download.')
    }

    try {
      const params = {
        vendorGlobalInsuranceID: vendorGlobalInsuranceID,
        asRawBase64: true,
        asBase64: true
      }

      const path = `/Vendors/VendorGlobalInsurance/GetCertificateFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded the certificate.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the certificate.')
    }
  }
}
