var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"coverages":""}},[_vm._l((_vm.list),function(coverage,indexA){return _c('div',{key:indexA,style:(Object.assign({}, {justifyContent: 'space-between'},
      (indexA !== _vm.list.length - 1 ? { marginBottom: '6px' } : {}),
      {padding: '0px 10px'})),attrs:{"coverages":""}},[_c('div',{staticClass:"pb-5",style:({ flexBasis: '33%' })},[(indexA == 0)?_c('div',{staticClass:"columns pt-5",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_c('div',{staticClass:"column is-6"},[_vm._v("Coverage Type")]),_c('div',{staticClass:"column is-2 has-text-right"},[_vm._v("Amount")])]):_vm._e(),(
          coverage.coverageType &&
            coverage.coverageType != undefined &&
            coverage.coverageAmount &&
            coverage.coverageAmount != undefined
        )?_c('div',{staticClass:"columns pb-5",style:({ color: _vm.color[8], height: '27px' })},[_c('div',{staticClass:"column is-6"},[_vm._v(_vm._s(coverage.coverageType))]),_c('div',{staticClass:"column is-2 has-text-right"},[_vm._v(_vm._s(_vm._f("currency")(coverage.coverageAmount)))])]):_c('div',{style:({ color: _vm.color[8] })},[_vm._v(" N/A ")])])])}),(!Array.isArray(_vm.list) || _vm.list.length === 0)?_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '6px',
      padding: '8px 12px',
      borderRadius: '6px',
      color: _vm.color[8]
    }),attrs:{"coverages-not-found":""}},[_vm._v(" Coverage type details were not found. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }