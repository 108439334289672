<template>
  <div>
    <h4 :style="{ margin: '0 10px' }">Coverages</h4>
    <Coverages :coverages="coverages" />
  </div>
</template>

<script>
import _get from 'lodash/get'
import Coverages from './Coverages'

export default {
  components: {
    Coverages
  },
  props: {
    coverages: Array
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color
    }
  }
}
</script>
