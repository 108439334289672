/*
  import { vendorInsuranceCoverage } from '@/services/Vendors/VendorInsuranceCoverages/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await vendorInsuranceCoverage.dispatch ('getVendorInsuranceCoverageList', {
      vendorID
    });
  */
  async getVendorInsuranceCoverageList({}, { vendorGlobalID }) {
    console.log('in getVendorInsuranceCoverageList...')
    try {
      const result = await kms.get('/Vendors/VendorGlobalInsurance/List', {
        params: {
          vendorGlobalID
        }
      })

      if (isDebug == true)
        console.debug('getVendorInsuranceCoverageList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The vendor contact insurance coverage list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorInsuranceCoverage = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
