import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  vendorID: 0,
  vendorGlobalID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'policyNumber',
      label: 'Policy #',
      aria: 'Policy #',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'verified',
      label: 'Verified',
      aria: 'Verified',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'insuranceCompany',
      label: 'Insurance Company',
      aria: 'Insurance Company',
      width: '25%',
      sortable: true,
      searchable: true
    },
    {
      field: 'agentName',
      label: 'Agent Name',
      aria: 'Agent Name',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'agentPhone',
      label: 'Agent Phone',
      aria: 'Agent Phone',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'agentEmail',
      label: 'Agent Email',
      aria: 'Agent Email',
      width: '25%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
