/*
  import { vendorList } from '@/services/Vendors/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await vendorList.dispatch ('getVendorList', {
      hoaId
    });
  */
  async getVendorList({}, { hoaId }) {
    console.log('in getVendorList...')
    try {
      const result = await kms.get('/Vendors/Vendor/List', {
        params: {
          hoaId
        }
      })

      if (isDebug == true) console.debug('getVendorList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The vendors list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Vendor by Id
  async getVendorById({}, { vendorID }) {
    try {
      console.debug('in getVendorById...' + vendorID)
      const vendor = await kms.get(`/Vendors/Vendor/${vendorID}`)

      if (isDebug == true) console.debug('getVendorById=' + JSON.stringify(vendor))

      return {
        result: vendor
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the vendor.`)
      console.error(exception)
    }

    return {
      vendor: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
